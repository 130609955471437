@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14213d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

span.vertical-timeline-element-icon.bounce-in {
  background: #14213d !important;
}

.vertical-timeline::before {
  background: #fca311 !important;
  top: 40px !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #fca311, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05) !important;
}

span.vertical-timeline-element-icon.bounce-in {
  color: #fca311 !important;
}

.vertical-timeline-element-content.bounce-in {
  background-color: #e5e5e5;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid #e5e5e5 !important;
}

span.vertical-timeline-element-date {
  color: #e5e5e5;
}

.vertical-timeline-element-content {
  box-shadow: 0 3px 0 #ff8e53 !important;
}

.twenty-twenty {
  text-align: center;
  color: lightgreen;
  z-index: -10;
  font-size: 30rem;
  position: absolute;
  top: -190px;
  opacity: 0.2;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  text-shadow: 6px 6px 0px #ff8e53;
}

.twenty-twenty-one {
  text-align: center;
  color: lightgreen;
  z-index: -10;
  font-size: 30rem;
  position: absolute;
  bottom: -510px;
  opacity: 0.2;
  margin: 0;
  line-height: 1.2;
  padding: 0;
  text-shadow: 6px 6px 0px #ff8e53;
}

.logo {
  text-align: center;
  font-family: "Berkshire Swash", cursive;
  color: #e5e5e5;
  font-size: 12rem;
}

.logo-text {
  text-align: center;
  color: #e5e5e5;
  margin-top: -35px;
  margin-bottom: 100px;
  font-size: 1.4rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1300px) {
  .twenty-twenty {
    font-size: 35vw;
    top: -100px;
  }
  .twenty-twenty-one {
    font-size: 35vw;
  }
}

@media only screen and (max-width: 1170px) {
  span.vertical-timeline-element-date {
    color: red;
    margin-left: 20px;
  }
  a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary {
    max-width: 100px;
    float: left;
  }
}

@media only screen and (max-width: 1000px) {
  .twenty-twenty-one {
    bottom: -150px;
  }
}

@media only screen and (max-width: 490px) {
  .logo {
    font-size: 6rem;
  }
  .logo-text {
    font-size: 0.8rem;
    margin-top: -10px;
  }
  .vertical-timeline::before {
    top: 30px !important;
  }
}

